import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

/**
 *
 * @param message
 * @param isLarge
 * @param isSmall
 * @param className
 * @param intl
 * @param style
 * @param color
 * @param props
 * @returns {*}
 * @constructor
 */
function Loader({
  isLarge = false,
  isSmall = false,
  className,
  intl,
  style,
  color,
  message = "app.shared.loading",
  ...props
}) {
  return (
    <span
      className={classNames("level-item", className)}
      style={{ display: "inline-flex" }}
    >
      <span
        className={classNames(
          "sk-circle",
          isLarge && "is-large",
          isSmall && "is-small",
          className
        )}
      >
        <span className="sk-circle1 sk-child" />
        <span className="sk-circle2 sk-child" />
        <span className="sk-circle3 sk-child" />
        <span className="sk-circle4 sk-child" />
        <span className="sk-circle5 sk-child" />
        <span className="sk-circle6 sk-child" />
        <span className="sk-circle7 sk-child" />
        <span className="sk-circle8 sk-child" />
        <span className="sk-circle9 sk-child" />
        <span className="sk-circle10 sk-child" />
        <span className="sk-circle11 sk-child" />
        <span className="sk-circle12 sk-child" />
      </span>
      {message && <FormattedMessage id={message} defaultMessage="Loading..." />}
    </span>
  );
}

Loader.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  isLarge: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default Loader;
