import { Field as FormField } from "react-final-form";

function parseValue(value, type) {
  if (type === "number") {
    return value && Number(value);
  }
  return value === "" ? "" : value;
}

function Field({ ...props }) {
  return (
    <FormField
      autoComplete="off"
      parse={(value) => parseValue(value, props.type)}
      {...props}
    />
  );
}

Field.propTypes = {
  ...FormField.propTypes,
};
export default Field;
