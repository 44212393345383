import {
  SET_FEATURES,
  RESET_CONFIG,
  SET_MANAGER_CONFIGURATION,
  SET_DEFAULTS,
} from "../actions";

const DEFAULT_CONFIGURATION = {
  FEATURES: [],
  MANAGER_TIMEOUT: undefined,
  DEFAULTS: [],
};

function initialState(managerConfiguration = DEFAULT_CONFIGURATION) {
  return {
    managerConfiguration,
    features: [],
    defaults: [],
  };
}

/**
 * Config reducer
 * Contains MANAGER configuration
 * @param state
 * @param action
 * @returns {*}
 */
function sysConfigReducer(state = initialState(), action) {
  const { managerConfiguration, features, defaults } = state;
  switch (action.type) {
    case RESET_CONFIG:
      return {
        ...state,
        ...initialState(managerConfiguration),
        features,
        defaults,
      };
    case SET_MANAGER_CONFIGURATION:
      return {
        ...state,
        managerConfiguration: action.value,
      };
    case SET_FEATURES:
      return { ...state, features: action.value };
    case SET_DEFAULTS:
      return { ...state, defaults: action.value };
    default:
      return state;
  }
}

export default sysConfigReducer;
