import React from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function NavBarItem({ to, children, ...rest }) {
  const history = useHistory();
  const { sysConfig } = useSelector(({ sysConfig }) => ({
    sysConfig,
  }));
  const defaults = sysConfig.defaults;

  function toDefault(event, url) {
    let toUrl = url;
    defaults?.forEach((d) => {
      if (url === d.route) {
        toUrl = d.default;
      }
    });
    if (toUrl !== url) {
      event.preventDefault();
      history.push(toUrl);
    }
  }

  return (
    <NavLink
      to={to}
      className="navbar-item"
      activeClassName="active"
      {...rest}
      onClick={(e) => {
        toDefault(e, to);
      }}
    >
      {children}
    </NavLink>
  );
}

NavBarItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default NavBarItem;
