import { useApolloClient } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { setFeatures, setDefaults } from "../actions";
import RoutePrivate from "../components/RoutePrivate";
import history from "../history";
// Views
import * as Errors from "../errors";
import ErrorBoundary from "../errors/ErrorBoundary";
import * as Layouts from "../layouts";
import { Login } from "./authentication";
import Home from "./home";

const History = React.lazy(() => import("./history"));
const Listings = React.lazy(() => import("./listings"));
const Live = React.lazy(() => import("./live"));
const Planning = React.lazy(() => import("./planning"));
const Scheduling = React.lazy(() => import("./scheduling"));
const Settings = React.lazy(() => import("./settings"));
const Stock = React.lazy(() => import("./stock"));
const Connect4i = React.lazy(() => import("./connect4i"));
const FEATURES_QUERY = loader("../layouts/DefaultLayout.query.graphql");

function Routes({ language }) {
  const lang = language.substring(0, 2);
  const dispatch = useDispatch();
  // Get DB features
  const client = useApolloClient();
  useEffect(() => {
    (async function getConfiguration() {
      client
        .query({
          query: FEATURES_QUERY,
        })
        .then(({ data }) => {
          const { features, defaults } = data;
          dispatch(setFeatures(features));
          dispatch(setDefaults(defaults));
        });
    })();
  }, [dispatch]);

  return (
    <Router history={history}>
      <Helmet
        htmlAttributes={{ lang, class: "default-layout" }}
        titleAttributes={{ lang }}
      />
      <ErrorBoundary>
        <Switch>
          <Route
            exact
            path={["/", "/authentication/login"]}
            render={() => (
              <Layouts.HomeLayout>
                <Switch>
                  {/* Home */}
                  <Route exact path="/" component={Home} />
                  {/* Authentication */}
                  <Route exact path="/authentication/login" component={Login} />
                </Switch>{" "}
              </Layouts.HomeLayout>
            )}
          />
          <RoutePrivate
            path={[
              "/scheduling",
              "/stock",
              "/planning",
              "/live",
              "/history",
              "/listings",
              "/connect4i",
              "/settings",
            ]}
            render={() => (
              <Layouts.DefaultLayout>
                <Switch>
                  <RoutePrivate path="/scheduling" component={Scheduling} />
                  <RoutePrivate path="/stock" component={Stock} />
                  <RoutePrivate path="/planning" component={Planning} />
                  <RoutePrivate path="/live" component={Live} />
                  <RoutePrivate path="/history" component={History} />
                  <RoutePrivate path="/listings" component={Listings} />
                  <RoutePrivate path="/connect4i" component={Connect4i} />
                  <RoutePrivate path="/settings" component={Settings} />
                </Switch>
              </Layouts.DefaultLayout>
            )}
          />
          {/* Errors */}
          <Route exact path="/errors/401" component={Errors.NotAuthorized} />
          {/* Fallback */}
          <Route component={Errors.NotFound} />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

if (window.Cypress) {
  window.tgHistory = history;
}
export default Routes;
