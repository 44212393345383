import React from "react";
import { FormattedMessage } from "react-intl";
import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";

function PlanningNavBar() {
  return (
    <NavBar>
      <div className="navbar-start">
        <NavBarItem
          to="/planning/scheduler"
          data-cy="navbar-planning-scheduler"
        >
          <span className="icon is-small">
            <i className="icon-sc-gantt-icn" />
          </span>
          <FormattedMessage
            id="app.pages.planning.navbar.scheduler"
            defaultMessage="Scheduler"
          />
        </NavBarItem>
        <NavBarItem
          to="/planning/configuration"
          data-cy="navbar-planning-configuration"
        >
          <span className="icon is-small">
            <i className="icon-st-general-icn" />
          </span>
          <FormattedMessage
            id="app.pages.planning.navbar.configuration"
            defaultMessage="Configuration"
          />
        </NavBarItem>
      </div>
    </NavBar>
  );
}

export default PlanningNavBar;
