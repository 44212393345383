import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

/**
 * Checks user permissions
 *
 * @param permissions
 * @param user
 * @param children
 * @return {*}
 * @constructor
 */
function HasPermission({ permissions = [], children }) {
  const user = useSelector(({ user }) => user.user);
  if (!user) {
    return <span />;
  }
  if (
    permissions.some((permission) => user.user.permissions.includes(permission))
  ) {
    return <>{children}</>;
  }
  return <span />;
}

HasPermission.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.any,
};

export default HasPermission;
