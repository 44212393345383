import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Container = styled.div`
  //background-image: linear-gradient(135deg,#0761AE,#2782c4, #439fd7 ,#59b5e6) !important;
`;
/**
 * Home page
 * @constructor
 */
function Home() {
  return (
    <Container className="hero-body">
      <div className="container">
        <p className="title">
          <FormattedMessage
            id="app.pages.home.title"
            defaultMessage="Agile Factory"
          />
        </p>
        <p className="subtitle">
          <FormattedMessage
            id="app.pages.home.subtitle"
            defaultMessage="Manufacturing Execution System Advanced"
          />
        </p>
      </div>
    </Container>
  );
}

export default Home;
