import React from "react";
import { FormattedMessage } from "react-intl";
import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";

function SchedulingNavBar() {
  return (
    <NavBar>
      <div className="navbar-start">
        <NavBarItem to="/scheduling/jobs" data-cy="navbar-scheduling-jobs">
          <span className="icon is-small">
            <i className="icon-sc-board-icn" />
          </span>
          <FormattedMessage
            id="app.pages.scheduling.navbar.production_orders"
            defaultMessage="Production orders"
          />
        </NavBarItem>
        <NavBarItem
          to="/scheduling/priority"
          data-cy="navbar-scheduling-priority"
        >
          <span className="icon is-small">
            <i className="icon-sc-priority-icn" />
          </span>
          <FormattedMessage
            id="app.pages.scheduling.navbar.priority"
            defaultMessage="Priority"
          />
        </NavBarItem>
        <NavBarItem
          to="/scheduling/templates"
          data-cy="navbar-scheduling-templates"
        >
          <span className="icon is-small">
            <i className="icon-sc-templates-icn" />
          </span>
          <FormattedMessage
            id="app.pages.scheduling.navbar.templates"
            defaultMessage="Templates"
          />
        </NavBarItem>
      </div>
    </NavBar>
  );
}

export default SchedulingNavBar;
