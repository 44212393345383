import React from "react";
import { FormattedMessage } from "react-intl";

import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";
import HasFeature from "../../components/helpers/HasFeature";

function StockNavBar() {
  return (
    <NavBar>
      <div className="navbar-start">
        {/* STOCK_ITEMS */}
        <HasFeature feature="STOCK:ITEMS">
          <NavBarItem to="/stock/items" data-cy="navbar-stock-items">
            <span className="icon is-small">
              <i className="icon-sc-catalog-icn" />
            </span>
            <FormattedMessage
              id="app.pages.stock.navbar.stock"
              defaultMessage="Scorte"
            />
          </NavBarItem>
        </HasFeature>
        {/* STOCK_CONTAINERS */}
        <HasFeature feature="STOCK:CONTAINERS">
          <NavBarItem to="/stock/containers" data-cy="navbar-stock-containers">
            <span className="icon is-small">
              <i className="icon-sc-containers-icn" />
            </span>
            <FormattedMessage
              id="app.pages.stock.navbar.containers"
              defaultMessage="Containers"
            />
          </NavBarItem>
        </HasFeature>
        {/* STOCK_FLOWS */}
        <HasFeature feature="STOCK:FLOWS">
          <NavBarItem to="/stock/flows" data-cy="navbar-stock-flows">
            <img
              src={
                require("../../images/navbars/stock/sc-stock-flows-icn.svg")
                  .default
              }
              alt=""
              width="30"
              height="30"
            />
            <FormattedMessage
              id="app.pages.stock.navbar.flows"
              defaultMessage="Flows"
            />
          </NavBarItem>
        </HasFeature>
        {/* STOCK_MOVEMENTS */}
        <HasFeature feature="STOCK:MOVEMENTS">
          <NavBarItem to="/stock/movements" data-cy="navbar-stock-movements">
            <span className="icon is-small">
              <i className="icon-swap-icn" />
            </span>
            <FormattedMessage
              id="app.pages.stock.navbar.movements"
              defaultMessage="Movements"
            />
          </NavBarItem>
        </HasFeature>
      </div>
    </NavBar>
  );
}

StockNavBar.propTypes = {};
export default StockNavBar;
